<template>
  <div class="login-form">
    <q-card flat bordered class="q-pa-lg">
      <q-card-section>
        <div class="text-center">
          <AppLogo />
          <div class="text-h6">Login</div>
          <div class="text-subtitle2">Login into dashboard</div>
        </div>
      </q-card-section>

      <q-form ref="formRef">
        <q-input
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Please input your valid email']"
          outlined
          v-model="form.email"
          dense
          placeholder="input your email"
        >
          <template v-slot:prepend>
            <q-icon name="email" />
          </template>
        </q-input>

        <q-input
          type="password"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Please input your password']"
          outlined
          v-model="form.password"
          dense
          placeholder="input your password"
        >
          <template v-slot:prepend>
            <q-icon name="vpn_key" />
          </template>
        </q-input>
        <div class="row q-gutter-y-sm">
          <q-btn class="full-width" color="primary" @click="onSubmit"> Login now </q-btn>
          <q-btn flat class="full-width" color="primary" @click="onGotoRegister"> New account </q-btn>
        </div>

        <ButtonLoginGoogle />
      </q-form>
    </q-card>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { localStore } from '@/utils/localstore'
import { APP_ROUTER_STORE_KEY } from '@/constants/vars'

import { QForm } from 'quasar'
import logging from '@/utils/logging'
import { ACTION_PROFILE } from '../user/profile-actions'
import LoginRegisterMixin from '@/components/account/mixins/LoginRegisterMixin.vue'
import ButtonLoginGoogle from '@/components/account/ui/ButtonLoginGoogle.vue'
import AppLogo from '@/components/common/AppLogo.vue'

@Options({
  components: { AppLogo, ButtonLoginGoogle },
  directives: { maska },
})
export default class LoginForm extends mixins(LoginRegisterMixin) {
  form = {
    email: '',
    password: '',
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  async onSubmit() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then((success: boolean) => {
        if (!success) {
          return
        }

        this.doLogin()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async doLogin() {
    const { email, password } = this.form
    const { dispatch } = this.$store

    if (!email || !password) {
      return
    }

    const user = await dispatch(ACTION_PROFILE.LOGIN, { email, password })
    if (user) {
      this.redirectAfterLogin()
    }
  }

  redirectAfterLogin() {
    const redirectLogin = localStore.getItem(APP_ROUTER_STORE_KEY.REDIRECT_LOGIN)
    if (redirectLogin) {
      this.$router.push(redirectLogin)
    } else {
      this.$goto('home')
    }
  }

  onGotoRegister() {
    this.$router.push({ name: 'register' })
  }
}
</script>
<style lang="scss" scoped>
.login-form {
  max-width: 500px;
  margin: auto;
  padding-top: 100px;
}
</style>
