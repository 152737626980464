
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import LoginForm from '@/components/account/LoginForm.vue'
import RegisterForm from '@/components/account/RegisterForm.vue'

@Options({
  components: { LoginForm, RegisterForm },
  directives: { maska },
})
export default class Login extends Vue {
  created() {
    this.$meta.setMeta({ title: ['Login'] })
  }
}
